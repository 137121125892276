import { Flex, Box } from "@chakra-ui/react";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";

export const AboutPage = () => {
  return (
    <>
      <Header />
      <Flex>
        <Box w="100%">TBC</Box>
      </Flex>
      <Footer />
    </>
  );
};
